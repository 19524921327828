import * as React from 'react';
import Image from '../../atoms/Image/Image';
import Text from '../../atoms/Text/Text';
import { css } from '@emotion/react';
import Fancybox from '../../atoms/Fancybox/Fancybox';

export default function SlideItem({
  keyEle,
  data,
  typeStyle,
  fancybox,
  index,
}) {
  let textCss = {};
  let sliderItemCss = {};
  let titleCss = {};
  let subtitleCss = {};
  let imageCss = {};
  let imageContentCss = {};
  let textAlignCss = 'initial';

  switch (typeStyle) {
    case 'normal':
      imageCss = css`
        height: calc(100vw * (calc(210 / var(--width_base))));
      `;
      break;
    case 'banner-page':
      textCss = css`
        width: calc(100vw * (calc(644 / var(--width_base))));
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(100vw * (calc(25 / var(--width_base))));
        z-index: 2;
        @media (max-width: 767px) {
          width: 100%;
          bottom: calc(100vw * (calc(30 / var(--width_base))));
        }
      `;

      titleCss = css`
        font-size: calc(100vw * (calc(39 / var(--width_base))));
        line-height: calc(100vw * (calc(41 / var(--width_base))));
        font-family: 'Montserrat Bold', sans-serif;
        @media (max-width: 767px) {
          font-size: calc(100vw * (calc(24 / var(--width_base))));
          line-height: calc(100vw * (calc(30 / var(--width_base))));
          padding-left: calc(100vw * (calc(30 / var(--width_base))));
        }
      `;

      subtitleCss = css`
        font-size: calc(100vw * (calc(25 / var(--width_base))));
        line-height: calc(100vw * (calc(25 / var(--width_base))));
        margin-top: calc(100vw * (calc(10 / var(--width_base))));
      `;
      imageContentCss = css`
        position: relative;
        &::before {
          content: '';
          position: absolute;
          height: calc(100vw * (calc(250 / var(--width_base))));
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          // background: -moz-linear-gradient(
          //   50% -15.24% -90deg,
          //   rgba(255, 255, 255, 0) 0%,
          //   rgba(0, 0, 0, 1) 100%
          // );
          // background: -webkit-linear-gradient(
          //   -90deg,
          //   rgba(255, 255, 255, 0) 0%,
          //   rgba(0, 0, 0, 1) 100%
          // );
          // background: -webkit-gradient(
          //   linear,
          //   50% -15.24%,
          //   50% 104.78%,
          //   color-stop(0, rgba(255, 255, 255, 0)),
          //   color-stop(1, rgba(0, 0, 0, 1))
          // );
          // background: -o-linear-gradient(
          //   -90deg,
          //   rgba(255, 255, 255, 0) 0%,
          //   rgba(0, 0, 0, 1) 100%
          // );
          // background: -ms-linear-gradient(
          //   -90deg,
          //   rgba(255, 255, 255, 0) 0%,
          //   rgba(0, 0, 0, 1) 100%
          // );
          // -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#000000' ,GradientType=0)";
          // background: linear-gradient(
          //   180deg,
          //   rgba(255, 255, 255, 0) 0%,
          //   rgba(0, 0, 0, 1) 100%
          // );
          // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=0);
        }
      `;
      sliderItemCss = css`
        position: relative;
      `;
      imageCss = css`
        width: 100%;
        min-height: calc(100vw * (500 / var(--width_base)));
        height: 95vh;
        object-position: bottom !important;
        @media (max-width: 767px) {
          height: 80vh;
          min-height: calc(100vw * (calc(200 / var(--width_base))));
        }
      `;
      break;
    case 'carousel3':
      sliderItemCss = css`
        padding-right: calc(100vw * (calc(15 / var(--width_base))));
        padding-left: calc(100vw * (calc(15 / var(--width_base))));
        @media (max-width: 767px) {
          padding-right: initial;
          padding-left: initial;
        }
      `;
      textCss = css`
        background-color: #5e5e5e;
        font-size: calc(100vw * (calc(14 / var(--width_base))));
        line-height: calc(100vw * (calc(14 / var(--width_base))));
        padding: calc(100vw * (calc(7 / var(--width_base))));
        @media (max-width: 767px) {
          font-size: calc(100vw * (calc(13 / var(--width_base))));
          line-height: calc(100vw * (calc(13 / var(--width_base))));
          background-color: initial;
          margin-top: calc(100vw * (calc(-30 / var(--width_base))));
          transform: translateY(calc(100vw * (calc(-23 / var(--width_base)))));
          position: relative;
          z-index: 1;
        }
      `;
      imageCss = css`
        height: calc(100vw * (calc(250 / var(--width_base))));
        @media (max-width: 767px) {
          height: calc(100vw * (calc(390 / var(--width_base))));
        }
      `;
      imageContentCss = css`
        position: relative;
        &::before {
          content: '';
          position: absolute;
          height: calc(100vw * (calc(65 / var(--width_base))));
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: -moz-linear-gradient(
            50% -15.24% -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          background: -webkit-linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          background: -webkit-gradient(
            linear,
            50% -15.24%,
            50% 104.78%,
            color-stop(0, rgba(255, 255, 255, 0)),
            color-stop(1, rgba(0, 0, 0, 1))
          );
          background: -o-linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          background: -ms-linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#000000' ,GradientType=0)";
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=0);
        }
      `;
      textAlignCss = 'center';
      break;
    case 'carousel4':
      sliderItemCss = css`
        position: relative;
        padding-right: calc(100vw * (calc(15 / var(--width_base))));
        padding-left: calc(100vw * (calc(15 / var(--width_base))));
        @media (max-width: 767px) {
          padding-left: initial;
          padding-right: initial;
        }
      `;
      imageContentCss = css`
        position: relative;
        &::before {
          content: '';
          position: absolute;
          height: calc(100vw * (calc(107 / var(--width_base))));
          opacity: 0.2;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: -moz-linear-gradient(
            50% -15.24% -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          background: -webkit-linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          background: -webkit-gradient(
            linear,
            50% -15.24%,
            50% 104.78%,
            color-stop(0, rgba(255, 255, 255, 0)),
            color-stop(1, rgba(0, 0, 0, 1))
          );
          background: -o-linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          background: -ms-linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#000000' ,GradientType=0)";
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=0);
        }
      `;
      textCss = css`
        font-size: calc(100vw * (calc(12 / var(--width_base))));
        line-height: calc(100vw * (calc(12 / var(--width_base))));
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(100vw * (calc(12 / var(--width_base))));
        z-index: 1;
        @media (max-width: 767px) {
          font-size: calc(100vw * (calc(14 / var(--width_base))));
          line-height: calc(100vw * (calc(14 / var(--width_base))));
          bottom: calc(100vw * (calc(30 / var(--width_base))));
        }
      `;
      textAlignCss = 'center';
      break;
    case 'carousel3beneficios':
      sliderItemCss = css`
        padding-right: calc(100vw * (calc(15 / var(--width_base))));
        padding-left: calc(100vw * (calc(15 / var(--width_base))));
        @media (max-width: 767px) {
          padding-left: initial;
          padding-right: initial;
        }
      `;
      imageCss = css`
        width: calc(100vw * (calc(218 / var(--width_base))));
        height: calc(100vw * (calc(362 / var(--width_base))));
        margin: auto;
        @media (max-width: 767px) {
          height: calc(100vw * (calc(390 / var(--width_base))));
        }
      `;
      imageContentCss = css`
        display: flex;
        justify-content: center;
        @media (max-width: 767px) {
        }
      `;
      textAlignCss = 'center';
      break;
    default:
      textCss = css``;
      sliderItemCss = css``;
      break;
  }
  const imageFancybox = css`
    height: calc(100vw * (calc(299 / var(--width_base))));
    cursor: pointer;
    margin: auto;
    object-fit: cover;
    width: 100%;
    @media (max-width: 767px) {
      //height: calc(100vw * (calc(236 / var(--width_base))));
      height: calc(100vw * (calc(390 / var(--width_base))));
    }
  `;
  return (
    <div key={keyEle} css={sliderItemCss} className="slider-carusel__item">
      {typeStyle === 'banner-page' ? (
        <>
          {data?.imagen && (
            <div css={imageContentCss} className="only-desk">
              <Image
                imagen={data?.imagen}
                styleCss={imageCss}
                classEle="slider-carusel__imagen"
              />
            </div>
          )}
          {data?.imagenMovil && (
            <div css={imageContentCss} className="only-mov line-0">
              <Image
                imagen={data?.imagenMovil}
                styleCss={imageCss}
                classEle="slider-carusel__imagen"
              />
            </div>
          )}
        </>
      ) : (
        <>
          {data?.imagen && (
            <div css={imageContentCss}>
              {fancybox === false && (
                <Image
                  imagen={data?.imagenFondo}
                  styleCss={imageCss}
                  classEle="slider-carusel__imagen"
                />
              )}
              {fancybox === true && (
                <Fancybox options={{ infinite: false }}>
                  {/*<img*/}
                  {/*  css={imageFancybox}*/}
                  {/*  src={data?.imagen?.sourceUrl}*/}
                  {/*  data-fancybox="gallery"*/}
                  {/*  data-caption={data?.texto}*/}
                  {/*  data-src={data?.imagen?.sourceUrl}*/}
                  {/*  alt={data?.texto}*/}
                  {/*/>*/}
                  <img
                    css={imageFancybox}
                    srcSet={
                      data?.imagen?.sourceUrlSharp?.childImageSharp
                        .gatsbyImageData.images.sources[1].srcSet
                    }
                    data-fancybox={`gallery-${index}`}
                    data-caption={data?.texto}
                    data-srcSet={
                      data?.imagen?.sourceUrlSharp?.childImageSharp
                        .gatsbyImageData.images.sources[1].srcSet
                    }
                    // alt={data?.texto}
                    alt={data?.imagen?.altText}
                  />
                </Fancybox>
              )}
            </div>
          )}
        </>
      )}

      <div css={textCss}>
        {data?.texto && (
          <Text
            data={data?.texto}
            styleCss={titleCss}
            colorText="white"
            align={textAlignCss}
            data-aos="fade-right"
            data-aos-delay="200"
          />
        )}
        {data?.subtitle && (
          <Text
            data={data?.subtitle}
            styleCss={subtitleCss}
            colorText="white"
            align={textAlignCss}
            data-aos="fade-right"
            data-aos-delay="300"
          />
        )}
      </div>
    </div>
  );
}
SlideItem.defaultProps = {
  fancybox: true,
};
